import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SolidButton from '../components/buttons/SolidButton'
import TransparentButton from '../components/buttons/TransparentButton'
import Helmet from 'react-helmet'
import logo from '../images/logo.svg'
import soai from '../images/State of AI_Divergence_2019_logo_WHT.svg'
import barclays from '../images/Barclays-logo-new.svg'
import up from '../images/up-arrow.svg'
import down from '../images/down-arrow.svg'
import { ModalContext } from '../components/WrapPageElement'
import fb from '../images/fb.jpeg'

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>MMC Ventures presents The State of AI 2019</title>
          <meta name="description" content="MMC Ventures" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="MMC Ventures" />
          <meta
            name="twitter:description"
            content="MMC Ventures presents The State of AI 2019"
          />
          <meta name="twitter:site" content="@MMC_Ventures" />
          <meta name="twitter:creator" content="@MMC_Ventures" />
          {/* <meta property="og:url" content={this.props.link} /> */}

          <meta property="og:title" content="MMC Ventures" />
          <meta
            property="og:description"
            content="MMC Ventures presents The State of AI 2019"
          />
          <meta property="og:site_name" content="The State of AI 2019" />
          <meta property="og:locale" content="en_UK" />
          <meta property="og:type" content="website" />
        </Helmet>
        <section
          className="row home"
          style={{
            backgroundImage:
              this.props.backgroundImage &&
              `url(${this.props.backgroundImage})`,
          }}
        >
          <div className="col-md-12 col-md-of2fset-3 text-center">
            <div className="homeContent">
              <img className="mainlogo" src={logo} />
              <p className="presents">presents</p>
              <img className="soai" src={soai} />

              <SolidButton
                url="/introduction"
                label="Explore"
                btnwidth="100%"
              />
              <TransparentButton
                label="Download the report"
                btnwidth="100%"
                onShowModal={this.props.showModal}
              />

              <div>
                <img src={barclays} className="barclays" />
              </div>
            </div>
          </div>
          <div className="arrows hidden-xs hidden-sm">
            <img src={up} />
            <p>You may navigate with arrows</p>
            <img src={down} />
          </div>
        </section>
      </Layout>
    )
  }
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <ModalContext.Consumer>
      {aye => (
        <IndexPage
          backgroundImage={
            page.featured_media ? page.featured_media.source_url : null
          }
          showModal={aye}
          title={page.title}
          link={page.link}
          content={page.content}
        />
      )}
    </ModalContext.Consumer>
  )
}

IndexPage.propTypes = {
  menu_order: PropTypes.func,
  //data: PropTypes.object.isRequired,
}

export default Page
export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      title
      id
      content
      link
      menu_order
      featured_media {
        source_url
      }
    }
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "test" } }) {
      edges {
        node {
          count
          items {
            order
            title
            object_slug
            wordpress_children {
              wordpress_id
              order
              title
              object_slug
            }
          }
        }
      }
    }
    allWordpressPage(sort: { fields: [menu_order] }) {
      edges {
        node {
          menu_order
          slug
          title
        }
      }
    }
  }
`
